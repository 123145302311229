import React from 'react';
import img1 from '../images/metal.jpg'; 
import img2 from '../images/nonferrous.jpg';
import img3 from '../images/battery.jpg';
import img4 from '../images/container.png';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Services</h2>
                    
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        We are deeply committed to the growth and success of our clients.
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Ferrous Metal Scrap" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img1} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Ferrous Metal Scrap (HMS)</h2>
                                <p className="text-md font-medium">
                                    Ferrous scrap consists of iron and steel. This includes scrap from old automobiles, household appliances, steel beams, railroad tracks, ships, and food packaging and other containers.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Non-Ferrous Metal Scrap" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Non-Ferrous Metal Scrap</h2>
                                <p className="text-md font-medium">
                                    We handle non-ferrous metals such as aluminum, copper, lead, nickel, and zinc. These metals are crucial for various industrial applications and have distinct recycling processes.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Battery Scrap" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Battery Scrap</h2>
                                <p className="text-md font-medium">
                                    We specialize in the handling and recycling of battery scrap, including lead-acid and lithium batteries. Proper management of battery scrap is essential for environmental protection and resource recovery.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Recycling and Waste Management" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Recycling and Waste Management</h2>
                                <p className="text-md font-medium">
                                    Our recycling and waste management services are designed to minimize environmental impact and maximize the value of recyclable materials.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;
