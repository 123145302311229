import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#about" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                Services
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/contact#contact" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                Contact Us
            </HashLink>
            <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/request-quote#demo" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                Request A Quote
            </HashLink>
        </>
    )
}

export default NavLinks;
